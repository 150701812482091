<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>love-bird</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.125,11.25a.375.375,0,0,1,.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9.75,11.625a.375.375,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.125,12a.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.5,11.625a.375.375,0,0,1-.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18,9.938,13.526,5.27a2.648,2.648,0,0,1-.5-3.056h0a2.648,2.648,0,0,1,4.24-.688L18,2.257l.731-.731a2.648,2.648,0,0,1,4.24.688h0a2.65,2.65,0,0,1-.5,3.056Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.026,17.968C3.675,18.169.75,17.485.75,14.25V13.5a.75.75,0,0,1,.75-.75H6V12a3.75,3.75,0,0,1,7.5,0v5.25a6,6,0,0,1-6,6H.75l2.625-2.625"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="13.5"
      y1="12.75"
      x2="15.75"
      y2="12.75"
    />
  </svg>
</template>
